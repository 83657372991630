<template>
  <div class="create-class-ticket">
    <div class="card-container wrapper">
      <div
        class="font-bold text-lg text-primary-100"
        style="margin-bottom: 16px"
      >
        {{ showEditClassTicket ? '編輯堂票' : '新增堂票' }}
      </div>
      <AiHintBlock
        v-if="!showEditClassTicket"
        :hintLoading="hintLoading"
        @confirm="hintConfirm"
      />
      <BaseElForm
        ref="formRef"
        v-loading="hintLoading"
        label-position="top"
        :model="formData"
        :rules="formRules"
      >
        <BaseElFormItem label="名稱" prop="name">
          <BaseElInput
            v-model="formData.name"
            data-cy="name-input"
            placeholder="堂票名稱"
          />
        </BaseElFormItem>
        <BaseElFormItem
          label="圖片"
          prop="image"
          class="form-relative-label others"
        >
          <UploadButton
            cyUploadBtn="ticket-img-upload-btn"
            cyUploadedImg="ticket-img-uploaded"
            :img="formData.avatar"
            :isAvatar="true"
            @change="loadImg"
          />
        </BaseElFormItem>
        <BaseElFormItem label="有效期限" prop="isExp">
          <BaseElFormItem v-if="(!ticketId) || copy" prop="expType">
            <div class="flex gap-5">
              <BaseElSelect
                v-model="formData.expType"
                name="isExp"
                class="w-[200px]"
                :disabled="noExp"
                @change="onExpTypeChange"
              >
                <BaseElSelectOption label="有效天數" value="expDay" />
                <BaseElSelectOption label="特定截止日期" value="specify" />
                <BaseElSelectOption label="領取當月最後一天" value="endOfPeriod" />
              </BaseElSelect>

              <BaseElFormItem prop="expDay">
                <BaseElInputNumber
                  v-if="formData.expType === 'expDay'"
                  v-model="formData.expDay"
                  :min="1"
                />
              </BaseElFormItem>
              <BaseElFormItem prop="expDate">
                <el-date-picker
                  v-if="formData.expType === 'specify'"
                  v-model="formData.expDate"
                  editable
                  type="date"
                  format="yyyy-MM-dd"
                  placeholder="選擇日期"
                  :picker-options="pickerOptions"
                />
              </BaseElFormItem>
            </div>
          </BaseElFormItem>

          <BaseElFormItem v-if="!ticketId" prop="isExp">
            <BaseElCheckbox v-model="noExp" @change="noExpChange">無上限</BaseElCheckbox>
          </BaseElFormItem>

          <p v-if="showEditClassTicket" class="form-edit-disable">
            {{ formatExpPreviewText({
              isExp: formData.isExp,
              expType: formData.expType,
              expDay: formData.expDay,
              endOfPeriodUnit: formData.endOfPeriodUnit,
              specifyExpiredDate: formData.specifyExpiredDate,
            }) }}
          </p>
        </BaseElFormItem>

        <!-- <BaseElFormItem v-if="showEditClassTicket && formData.isExp && formData.expDay" label="有效天數">
          <p class="form-edit-disable">
            {{ formData.expDay }}
          </p>
        </BaseElFormItem>

        <BaseElFormItem v-if="showEditClassTicket && formData.isExp && formData.expDate" label="特定截止日期">
          <p class="form-edit-disable">
            {{ `${formatDate(formData.expDate, 'YYYY/MM/DD HH:mm:ss')}` }}
          </p>
        </BaseElFormItem> -->

        <BaseElFormItem
          v-if="useTicketUsagePermission"
          prop="isClientUsageAllowed"
          label="開放客人核銷"
        >
          <BaseElSwitch
            v-if="showCreateOption"
            v-model="formData.isClientUsageAllowed"
            active-text="開啟"
            inactive-text="關閉"
          />
          <p v-if="showEditClassTicket" class="form-edit-disable">
            {{ formData.isClientUsageAllowed ? '開啟' : '關閉' }}
          </p>
        </BaseElFormItem>
        <BaseElFormItem
          v-if="useTicketGivePermission"
          prop="isValuable"
          label="開放客人轉贈"
        >
          <BaseElSwitch
            v-if="showCreateOption"
            :value="!formData.isValuable"
            active-text="開啟"
            inactive-text="關閉"
            @change="formData.isValuable = !formData.isValuable"
          />
          <p v-if="showEditClassTicket" class="form-edit-disable">
            {{ !formData.isValuable ? '開啟' : '關閉' }}
          </p>
        </BaseElFormItem>
        <BaseElFormItem label="可使用張數" prop="availableTimes">
          <BaseElInputNumber
            v-if="showCreateOption"
            v-model="formData.availableTimes"
            class="w-[200px]"
            :min="1"
          />
          <p v-if="showEditClassTicket" class="form-edit-disable">
            {{ `${formData.availableTimes} 張` }}
          </p>
        </BaseElFormItem>
        <BaseElFormItem v-if="useBindServices" props="appointmentServices">
          <template>
            <FormLabelWithHint labelSize="16px" text="綁定服務項目" hint="( 綁定服務項目後，可在預約該服務項目時使用此堂票進行預約 )" />
          </template>
          <ServiceSearch multiple :collapse-tags="true" style="width: 560px;" :model.sync="formData.appointmentServices" />
        </BaseElFormItem>
        <BaseElFormItem label="敘述" prop="description">
          <quillEditor
            ref="quill"
            v-model="formData.description"
            :options="editorOption"
          />
        </BaseElFormItem>
        <BaseElFormItem label="注意事項" prop="notice">
          <quillEditor
            ref="notice"
            v-model="formData.notice"
            :options="editorOption"
          />
        </BaseElFormItem>
        <BaseElFormItem label="排序" prop="order">
          <BaseElInput
            v-model="formData.order"
            data-cy="order-input"
            placeholder="請輸入排序"
          />
        </BaseElFormItem>
      </BaseElForm>
    </div>

    <ImageCropper
      v-if="uploadDialog"
      :image="formData.img"
      @uploaded="getImage"
      @close="uploadDialog = false"
    />
    <PageFixedFooter
      :confirmLoading="loading"
      @cancel="$router.push({ name: 'ClassTicketSetting' })"
      @confirm="handleConfirm"
    />
  </div>
</template>

<script>
import { defineComponent, ref, computed, onMounted, set } from 'vue'
import { quillEditor } from 'vue-quill-editor'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import { isDigitRules, maxNumberRules, noEmptyRules, noPastDate } from '@/validation'
import UploadButton from '@/components/Button/UploadButton.vue'
import ImageCropper from '@/components/ImageCropper.vue'
import ServiceSearch from '@/components/Search/ServiceSearch.vue'
import FormLabelWithHint from '@/components/Form/FormLabelWithHint.vue'
import { usePermissions } from '@/use/permissions'
import {
  CreateClassTicket,
  FindClassTicket,
  UpdateClassTicket,
} from '@/api/classTicket'
import store from '@/store'
import { useRoute, useRouter } from 'vue-router/composables'
import formUtils from '@/utils/form'
import { useBaseForm, mappingSyncFormData, onFormRulesChangeClearValidate } from '@/use/useForm'
import { useFetch } from '@/use/fetch'
import { formatDate } from '@/utils/date'
import { map, keys, has, get } from 'lodash'
import dayjs from '@/lib/dayjs'
import AiHintBlock from '@/components/AiHintBlock.vue'
import { useAi } from '@/use/useAi'
import { hint } from '@/config/hint'
import { useClassTicket } from '@/use/classTicket'
export default defineComponent({
  name: 'createClassTicket',
  components: { UploadButton, quillEditor, ImageCropper, ServiceSearch, FormLabelWithHint, AiHintBlock },
  setup () {
    const route = useRoute()
    const router = useRouter()
    const {
      initFormData, formData, formRef, loading,
    } = useBaseForm()
    const { formatExpPreviewText } = useClassTicket()
    const { simpleFetch } = useFetch()
    const shopId = computed(() => store.getters.shop)
    const ticketId = computed(() => route.params.ticketId)
    const copy = computed(() => route.query.copy)
    const showCreateOption = computed(() => !ticketId.value || copy.value)
    const showEditClassTicket = computed(() => ticketId.value && !copy.value)
    const avatarChanged = ref(false)
    const uploadDialog = ref(false)
    const { checkAction, checkActionList } = usePermissions()
    //  開放核銷
    const useTicketUsagePermission = computed(() => {
      return checkAction('admin.classTicketConfig.clientUsage')
    })

    //  開放轉贈
    const useTicketGivePermission = computed(() => {
      return checkAction('admin.classTicketConfig.clientGive')
    })

    const useBindServices = computed(() => {
      return checkActionList(['admin.classTicket.bindAppointmentService', 'admin.appointmentService.find'], 'union')
    })

    const { configurationHint, hintLoading, setData } = useAi()

    const hintConfirm = async (prompt) => {
      const res = await configurationHint(get(hint, 'classTicket.key'), prompt)
      if (!res) {
        hintLoading.value = false
        return
      }
      setData(formData, res)
      if (get(res.hint, 'expType') === 'expDay') formData.expType = 'expDay'
      if (get(res.hint, 'expType') === 'specifyExpiredDate') formData.expType = 'specify'
    }
    const noExp = ref(true)
    initFormData({
      name: null,
      image: null,
      avatar: null,
      isExp: false,
      description: null,
      expDay: null,
      expDate: null,
      endOfPeriodUnit: null,
      availableTimes: 1,
      clientUsageTimesMax: 1,
      clientUsageTimes: false,
      isValuable: true,
      isClientUsageAllowed: false,
      order: 100,
      expType: null,
      appointmentServices: null,
    })

    const pickerOptions = {
      disabledDate: (date) => {
        return dayjs(date).isSameOrBefore(dayjs(), 'd')
      },
    }

    const editorOption = {
      placeholder: '請輸入...',
      modules: {
        toolbar: [
          ['bold', 'italic', 'underline', 'strike'],
          [{ list: 'bullet' }],
          ['link'],
        ],
      },
    }

    const formRules = computed(() => {
      const rules = {
        name: [noEmptyRules()],
        order: [noEmptyRules(), isDigitRules()],
        description: [noEmptyRules()],
      // notice: noEmptyRules(),
      }

      if (formData.isExp) rules.expType = [noEmptyRules()]
      if (formData.expType === 'expDay') rules.expDay = [noEmptyRules(), isDigitRules(), maxNumberRules(9999)]
      if (formData.expType === 'specify') rules.expDate = [noEmptyRules(), noPastDate()]
      return rules
    })

    onFormRulesChangeClearValidate(formRef, formRules)

    const loadImg = (img) => {
      formData.img = img
      uploadDialog.value = true
    }

    const getImage = (data) => {
      formData.avatar = data
      avatarChanged.value = true
      uploadDialog.value = false
    }

    //  新增堂票
    const createClassTicket = async () => {
      const {
        name,
        description,
        notice,
        availableTimes,
        isExp,
        expDay,
        expDate,
        expType,
        endOfPeriodUnit,
        order,
        isValuable,
        isClientUsageAllowed,
        avatar,
        appointmentServices,
      } = formData

      const [, err] = await CreateClassTicket({
        shopId: shopId.value,
        name,
        description,
        notice: notice || undefined,
        availableTimes,
        isExp,
        expType,
        expDay: Number(expDay) || undefined,
        endOfPeriodUnit,
        specifyExpiredDate: expDate ? dayjs(expDate).endOf('d').toDate() : undefined,
        order,
        isValuable: isValuable,
        isClientUsageAllowed,
        image: avatar?.id,
        appointmentServices: map(appointmentServices, 'id'),
      })
      if (err) {
        window.$message.error(err)
        return
      }
      window.$message.success('新增票券成功!')
      router.push({ name: 'ClassTicketSetting' })
    }

    //  更新堂票，id經由syncData寫入
    const updateClassTicket = async () => {
      const { name, avatar, description, appointmentServices, notice, id } = formData
      const [, err] = await UpdateClassTicket({
        shopId: shopId.value,
        id,
        name,
        description,
        notice: notice,
        image: avatarChanged.value ? avatar.id : undefined,
        appointmentServices: map(appointmentServices, 'id'),
        order: formData.order,
      })
      if (err) {
        window.$message.error(err)
        return
      }
      window.$message.success('更新票券成功!')
      router.push({ name: 'ClassTicketSetting' })
    }

    const syncData = (res) => {
      mappingSyncFormData({ Image: 'avatar', specifyExpiredDate: 'expDate', AppointmentServices: 'appointmentServices', expDate: 'specifyExpiredDate' }, res, formData)
      if (formData.expDate) formData.expType = 'specify'
      else if (formData.expDay) formData.expType = 'expDay'
    }

    const handleConfirm = async () => {
      loading.value = true
      const pass = await formUtils.checkForm(formRef.value)
      if (!pass) {
        loading.value = false
        return
      }
      if (showEditClassTicket.value) await updateClassTicket()
      else await createClassTicket()
      loading.value = false
    }

    const onExpTypeChange = (type) => {
      formData.expDay = null
      formData.expDate = null
      formData.endOfPeriodUnit = null
      if (type === 'endOfPeriod') {
        formData.endOfPeriodUnit = 'month'
      }
    }

    const noExpChange = (val) => {
      formData.isExp = !val
      formData.expDay = null
      formData.expDate = null
      formData.expType = null
    }

    onMounted(() => {
      if (ticketId.value) {
        simpleFetch(FindClassTicket, {
          shopId: shopId.value,
          id: ticketId.value,
        },
        (res) => {
          syncData(res)
        })
      }
    })

    return {
      pickerOptions,
      ticketId,
      formData,
      editorOption,
      loadImg,
      uploadDialog,
      getImage,
      formRules,
      useTicketUsagePermission,
      useTicketGivePermission,
      handleConfirm,
      loading,
      formRef,
      formatDate,
      onExpTypeChange,
      useBindServices,
      copy,
      showEditClassTicket,
      showCreateOption,
      hintConfirm,
      hintLoading,
      noExp,
      noExpChange,
      formatExpPreviewText,
    }
  },
})
</script>

<style scoped lang="postcss">
::v-deep .el-select .el-input {
  @apply w-full;
}

::v-deep .el-input-number,
::v-deep .el-input-number .el-input {
  @apply w-[300px];
}

.wrapper {
  padding: 20px !important;
  padding-left: 29px !important;
}

.form-relative-label {
  @apply relative;
}

.form-relative-label.others::after {
  content: '(建議上傳尺寸1200x1200)';
  @apply absolute left-[50px] top-[2px] text-sm text-gray-80;
}

.el-form-item__label {
  @apply p-0;
}

.ql-container {
  @apply h-[200px];
}

.form-edit-disable {
  color: #606266;
}
</style>
